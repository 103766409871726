/* FOOTER SECTION STARTS HERE */

.qualityIconsContainer{
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.qualityIcons{
    display: flex;
    gap: 20px;
}

.qualityIcons img{
    width: 55px;
    height: auto;
    object-fit: contain;
}

.footerSection{
    background-color: white;
    padding: 16px 0px;
    box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.1);
    display: flex;
}

.qrCodeSection{
    border: 1px solid #E93396;
    border-radius: 10px 0px 0px 10px;
    border-right: 0px !important;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    width: 60%;
}

.qrCode{
    width: 60px;
    height: auto;
    object-fit: contain;
}

.playstoreIconContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.appleAppIcon{
    width: 80px;
    height: auto;
    object-fit: contain;
}

.androidAppIcon{
    width: 90px;
    height: auto;
    object-fit: contain;
}

.footerTextOne{
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0px;
    color: black;
}

.footerTextTwo{
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    margin: 0;
    color: black;
}
