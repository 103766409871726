
.ProfileUpdate{
    width: 100%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    /* padding: 30px; */
    overflow: auto;
    margin-bottom: 50px;
}

.ProfileUpdatePhotos{
    display: flex;
    flex-direction: row;
    gap: 100px;
    align-items: center;
    margin-left: 30px;
    margin-top: 30px;
}

.profileUpdateImages{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.imageBorder {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px dashed #919eab52;
    margin-bottom: 30px;
}

.userVersion {
    font-size: 10px;
    font-weight: 700;
    color: var(--primary-color);
    background-color: var(--primary-background-color);
    width: fit-content;
    margin: 0;
    padding: 6px 20px;
    border-radius: 5px;
}

.keyValue{
    color: var(--text-primary);
}

.keyValue, .newValue, .oldValue{
    margin: 0;
    padding: 0;
}

.actions{
    margin-top:  50px;
    margin-bottom: 30px;
    margin-left: 30px;
}

.actionBtnGroups{
    display: flex;
    flex-direction: row;
    gap: 50px;
    align-items: center;
}

.comment{
    padding-right: 30px;
    height: 50px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 600px) {
    .ProfileUpdatePhotos{
        display: flex;
        flex-direction: column;
        gap: 0px;
        align-items: center;
    }
}