* {
  box-sizing: border-box;
}
body {
  margin: 0;
  /* font-family: "Baloo Tammudu 2", Sans-Serif; */
  font-family: 'Ubuntu', sans-serif;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
