.admins {
    margin: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.expandIcons {
    margin-left: 10px;
}

/* accordion */

.accordion {
    display: block;
    background-color: rgba(99, 115, 129, 0.03);
    padding: 20px 16px;
    margin-bottom: 20px;
    transition: all 0.5s;
}

.accordianContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordionKey {
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
}

.accordionValue {
    font-size: 12px;
    font-weight: 600;
}

.iconBoxContainer {
    display: flex;
    align-items: center;
    justify-content: right;
}