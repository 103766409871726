.manageSection{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.action{
    width: 100%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    border: none;
    outline: none;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.deleteHeading{
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0;
}

.deleteMsg{
    font-size: 12px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.deleteBtn{
    border: none;
    outline: none;
    border-radius: 8px;
    color: white;
    font-weight: 800;
    font-size: 14px;
    width: fit-content;
    height: 40px;
    padding: 0 20px;
    background: #B72136;
}

.deleteDivider{
    width: 100%;
    background: #DCE0E4;
    height: 5px;
}

.deleteConfirmCheck{
    display: flex;
}

.label{
    margin-left: 20px;
    font-size: 12px;
    font-weight: 600;
}

.btnGroup{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.checkbox {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border: 1px solid #212B36;
    border-radius: 2px;
    outline: none;
    background: url(../../images/check.svg) no-repeat;
    background-size: 13px;
    background-position: center;
}

.checkbox:checked {
    background-color: #212B36;
}