.emptyPage {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.emptyPageContents {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.emptyPageImage {
    width: 300px;
    height: auto;
    object-fit: fill;
}

.emptyPageHeading {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 50px;
    text-align: center;
}

.emptyPagePara {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .emptyPageImage{
        width: 200px;
    }
    .emptyPageHeading {
        font-size: 16px;
    }
    .emptyPagePara {
        font-size: 14px;
    }
}