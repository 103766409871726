
.container {
    margin: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.actionBtnsContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.title{
    padding: 0;
    margin: 0;
    width: 80%;
    color: var(--text-secondary);
    font-size: 12px;
    font-weight: 500;
    word-wrap: break-word;
}

.expandIcons {
    margin-left: 10px;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgba(99, 115, 129, 0.03);
    padding: 20px 16px;
    margin-bottom: 20px;
    transition: all 0.5s;
}

.accordianContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordionKey {
    font-size: 12px;
    width: 100px;
    font-weight: 300;
    margin-right: 20px;
    margin-bottom: 0;
    padding: 0;
}

.accordionValue {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.actionKeys{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
}