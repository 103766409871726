
.userProfileHeader{
    display: flex;
    gap: 20px;
    align-items: center;
}

.arrowBackBtnContainer{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 8px;
    display: none;
}
.arrowBackBtnContainer:hover{
    background-color: #fafbfb;
}
.arrowBackBtn{
    size: 30px;
}

@media only screen and (max-width: 1200px) {
    .arrowBackBtnContainer {
        display: flex;
    }
}