.errorPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    overflow: auto;
}

.errorPageContents {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.errorPageHeading {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.errorPageText {
    width: 100%;
    max-width: 600px;
    min-width: 250px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
}

.errorImage {
    width: 100%;
    max-width: 600px;
    min-width: 300px;
    height: auto;
    object-fit: fill;
    margin: 20px 0;
}

.backToHomeBtn {
    text-decoration: none;
}

@media only screen and (max-width: 600px) {
    .errorPageHeading {
        font-size: 20px;
    }

    .errorPageText {
        font-size: 14px;
    }
}