@import '../App.css';

.headerSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Cards section */

.analyticsCardContainer{
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    flex-wrap: wrap;
}

.analyticsCard{
    width: calc(33.33% - 15px);
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    border: none;
    outline: none;
    padding: 20px;
    height: 132px;
}

.analyticsCard p{
    margin: 0;
    padding: 0;
}

.analyticsCard span{
    font-size: 10px;
}

.analyticsCard h2{
    margin-top: 16px;
    margin-bottom: 0;
}

.chartContainer{
    height: 500px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 900px) {
    .analyticsCard{
        width: calc(50% - 10px);
    }
}

@media only screen and (max-width: 600px) {
    .analyticsCardContainer{
        flex-direction: column;
        align-items: center;
        gap: 20px;
        flex-wrap: nowrap;
    }
    .analyticsCard{
        width: 100%;
    }
}