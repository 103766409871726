
.userRequestsHeaderSection{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
}


.UserRequests {
    margin-bottom: 100px;
    padding: 20px;
    width: 100%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    border: none;
    outline: none;
}

.requests {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.request{
    padding-bottom: 20px;
    border-bottom: 1px solid #E5E5E5;
}

.requestMsg {
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 20px;
}

.requestedName {
    font-size: 14px;
}

.requestStatusWrap {
    display: flex;
    align-items: center;
    gap: 10px;
}

.notificationIcon {
    margin-bottom: 2px;
    margin-right: 5px;
}

.requestTime {
    font-size: 12px;
    font-weight: 400;
    color: #637381;
}

.headingContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}

.totalCount{
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: var(--primary-color);
    border: 1px solid #ddd;;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 8px;
}

@media only screen and (max-width: 700px) {
    .userRequestsHeaderSection {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
}