
.iconBox{
    display: flex;
    align-items: center;
    justify-content: center;
}

.icons{
    display: flex;
}

.editIcon{
    margin-right: 30px;
}

.editIcon, .deleteIcon{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.editIcon:hover, .deleteIcon:hover{
    background-color: #919eab14;
}