/* .react-tel-input .flag-dropdown{ */
.react-tel-input .flag-dropdown,
.react-tel-input .form-control {
    height: 100%;
    border: none;
    background-color: #fff;
    border-radius: 12px;
}

.react-tel-input .country-list {
    max-height: 150px;
}

.form-control .open {
    border-radius: 12px;
}

.flag-dropdown .open {
    border-radius: 12px;
}

.form-control:focus {
    border-color: #e2e2e2;
    box-shadow: none;
}

.react-tel-input .form-control {
    width: 100%;
    color: #637381;
}