
.loadingScreenContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.loadingContainer{
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
}

.loadingContainer h1{
    font-size: 20px;
    font-weight: 600;
    color: var(--text-primary);
    margin-top: 30px;
    margin-bottom: 0;
}