.breadCrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 50PX;
}

.activeLink {
    font-size: 14px;
    color: #212B36;
    font-weight: 400;
    text-decoration: none;
}

.activeLink:hover {
    color: #212B36;
    text-decoration: underline;
}

.inActiveLink {
    color: #637381;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.dot {
    width: 10px;
    color: #637381;
}

.contents {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.updateDetails,
.updateProfilepic {
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    height: 500px;
    padding: 20px;
}

.updateProfilepic {
    flex-basis: 33.3333%;
    flex-grow: 0;
    max-width: 33.3333%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.updateProfilepicContents {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.adminName {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0;
}


/* update information */

.updateDetails {
    flex-basis: 66.6667%;
    flex-grow: 0;
    max-width: 66.6667%;
}

.account {
    font-size: 16px;
    font-weight: 600;
}

.info {
    font-size: 12px;
    margin: 0;
}

.horizontalLine {
    width: 100%;
    color: #E5E5E5;
    margin: 20px 0;
}

.editForm {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.buttonGrp {
    display: flex;
    gap: 20px;
}

@media only screen and (max-width: 900px) {
    .contents {
        flex-direction: column;
        height: auto;
    }

    .updateProfilepic,
    .updateDetails {
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
        padding: 30px 20px;
        height: max-content;
    }
    .editForm {
        width: 90%;
    }
}

@media only screen and (max-width: 600px) {
    .editForm {
        width: 100%;
    }
}