.appbar {
    width: 100%;
    height: auto;
    display: inline-block;
    margin-top: 40px;
    margin-bottom: 50px;
}

.components{
    float: right;
    display: flex;
    align-items: center;
    gap: 20px;
}

.notificationIcon {
    width: 30px;
}

.menuIcon {
    width: 30px;
    display: none;
}

@media only screen and (max-width: 1200px) {
    .appbar {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .menuIcon {
        display: inline;
    }
}