
.successStoryPage{
    margin-bottom: 100px;
}

.successStoryContainer{
    margin-top: 20px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    width: 100%;
    height: auto;
    padding: 20px;
}

.subTitles{
    font-size: 14px;
    font-weight: 600;
    color: var(--text-primary);
}

.contentContainer{
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.keyValuepairs{
    display: flex;
    align-items: center;
    flex-direction: row;
}

.userKeys{
    width: 200px;
}

.dateKeys{
    display: block;
    width: 170px;
}

.userKeys, .dateKeys, .value{
    font-size: 12px;
    font-weight: 600;
    color: var(--text-secondary);
    margin: 0;
    padding: 0;
}

.actionBtns{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin: 20px 0;
}

.description{
    width: 80%;
    font-size: 12px;
    font-weight: 600;
    color: var(--text-secondary);
    padding: 0;
    text-align: left;
    line-height: 2;
    margin: 20px 0;
}

.attchments{
    margin: 20px 0;
    width: 100%;
    padding-top: 20px;
}

@media only screen and (max-width: 600px) {
    .description {
        width: 100%;
    }
}