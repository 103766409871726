
.autoSelectContainer{
    width: 100%;
}
.autoSelect{
    border: 1px solid var(--input-inactive);
    border-radius: 8px;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
    font-weight: 400;
    height: 40px;
}

.autoSelectedItem{
    width: 100%;
    height: 100%;
    padding-left: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.autoSelectedItemContent{
    display: flex;
    align-items: center;
    gap: 10px;
}

.autoSelectInput{
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-right: 20px;
    gap: 10px;
}

.accordion{
    margin-top: 5px;
    width: 100%;
    min-height: fit-content;
    max-height: 300px;
    overflow-y: auto;
    background-color: white;
    position: relative;
    z-index: 2;
    box-shadow: 0 1px 2px 0 #00000008, 0 1px 6px -1px #00000005, 0 2px 4px 0 #00000005;
    border-radius: 8px;
}

.empty{
    margin: 0;
    padding-left: 16px;
    font-size: 2px;
    color: var(--text-secondary);
}