@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Public Sans', sans-serif;
}

:root {
    --primary-color: #EA86B6;
    --primary-background-color: #FCEEF5;
    --global-background-color: #FFFFFF;
    --text-primary: #212B36;
    --text-secondary: #637381;
    --highlight-primary: #54d62c29;
    --highlight-neutral: #f6f7f8;
    --input-inactive: #DCE0E4;
    --header-background-primary: #F4F6F8;
    --divider-primary: #E5E5E5;

    --danger-color: #B72136;
    --danger-background: #f8e8eb;
    --success-color: #00AB56;
    --success-background: #eaf8f1;
    --warning-color: #F3B702;
    --warning-background: #fff9e6;
}

.content {
    width: 100%;
    margin-top: 50px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    border: none;
    outline: none;
}

h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: var(--text-primary);
}

h2 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: var(--text-primary);
}

p {
    color: var(--text-secondary);
}

input[type='text'],
input[type='date'],
input[type='number'],
input[type='email'] {
    width: 100%;
    height: 100%;
    border: 1px solid var(--input-inactive);
    border-radius: 8px;
    outline: none;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
    font-weight: 400;
    padding-left: 10px;
}

.css-s3631q-MuiTypography-root{
    font-size: 14px !important;
    margin: 0;
}

/* date picker styles */

.css-wpaink-MuiInputBase-root-MuiInput-root:before, .css-wpaink-MuiInputBase-root-MuiInput-root:after{
    border: none !important;
}

.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
    width: 98% !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input{
    font-size: 14px !important;
    color: var(--text-secondary) !important;
}

/* date picker styles ------------------------------- */

textarea {
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}

.phoneInput {
    width: 100%;
    height: 40px;
    border: 1px solid #DCE0E4;
    border-radius: 8px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.textInput {
    width: 100%;
    height: 40px;
}

.form-check-label {
    font-size: 14px;
    color: var(--text-secondary);
}

.form-check-input:checked {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.form-check-input[type=radio] {
    margin-top: 6px;
}

thead {
    background-color: #F4F6F8;
}

/*  VIEWS */

.desktopView {
    display: block;
}

.mobileView {
    display: none;
}

@media only screen and (max-width: 900px) {
    .desktopView {
        display: none;
    }

    .mobileView {
        display: block;
    }
}

/* radio */
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    font-size: 13px;
    padding-left: 28px;
    cursor: pointer;
    line-height: 14px;
    display: inline-block;
    color: var(--text-secondary);
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 14px;
    height: 14px;
    border: 1px solid var(--text-secondary);
    border-radius: 100%;
    background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: black;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.MuiOutlinedInput-notchedOutline{
    border: 0 !important;
    width: 100% !important;
    height: 100% !important;
    outline: none !important;
    font-size: 14px !important;
    line-height: 18px !important;
    color: var(--text-secondary) !important;
    font-weight: 400 !important;
    padding-left: 10px !important;
}

/* upload image preview section */

.ant-upload-list-item-error{
    border-color:  var(--input-inactive) !important;
}

/* date picker */

.ant-picker{
    height: 100% !important;
}

.ant-picker:hover , .ant-picker-focused {
    border-color: var(--input-inactive) !important;
    box-shadow: none !important
}

.ant-picker-active-bar{
    background: var(--primary-color) !important;
}

.ant-picker-cell-inner::before {
    border: 1px solid var(--primary-color) !important;
}

/* sliding buttons */

.ant-segmented {
    padding: 5px !important;
}

.ant-segmented .ant-segmented-item-label {
    padding: 5px 15px  !important;
}