.contents {
    margin-top: 50px;
}

.container {
    width: 100%;
    height: auto;
    border-radius: 16px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    padding: 0 20px;
    overflow-x: auto;
    margin-bottom: 50px;
}

.notificationContainer{
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    padding: 20px 0;
}

.notification {
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.notificationContent {
    margin-left: 30px;
}

.notificationMsg {
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 10px;
}

.notificationStatusWrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.notificationStatus {
    font-weight: 500px;
    font-size: 10px;
    text-transform: uppercase;
    color: #B72136;
    background-color: #b721361a;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    width: fit-content;
}

.notificationIcon {
    margin-bottom: 2px;
}

.notificationTime {
    display: inline;
    font-size: 10px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    margin-left: 10px;
}
@media only screen and (max-width: 600px) {
    .notificationMsg {
        font-size: 12px;
    }
}
@media only screen and (max-width: 400px) {
    .notificationStatusWrap {
        flex-direction: column;
        align-items: flex-start;
    }
    .notificationTime {
        margin-left: 0;
        margin-top: 10px;
    }
}