.container{
    position: absolute;
    right: 0;
    top: 0;
    height: auto;
    width: calc(100% - 300px);
    padding-right: 20px;
    background-color: #ffffff;
}

@media only screen and (max-width: 1200px) {
    .container{
        width: 100%;
        padding: 0 20px;
    }
}