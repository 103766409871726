.contacts {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.basicDetails {
    width: 100%;
    flex-basis: 66.6666%;
    flex-grow: 0;
    max-width: 66.6666%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    height: auto;
    padding: 20px;
}

.preferenceBox {
    margin-top: 30px;
}

.preferenceStatus {
    font-size: 13px;
    font-weight: 600;
    color: #637381;
    margin-top: 20px;
    padding: 0;
}

.contents {
    width: 100%;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.basicDetailsBox {
    width: 100%;
    flex-basis: 50%;
    flex-grow: 0;
    max-width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.basicDetailsRow {
    display: flex;
}

.basicDetailsKey{
    width: 150px;
}
.basicDetailsKey, .basicDetailsValue {
    font-size: 12px;
    font-weight: 600;
    color: #637381;
    margin: 0;
    padding: 0;
}

.verifiedEmail {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
    gap: 10px;
}

@media only screen and (max-width: 900px) {
    .contacts {
        flex-direction: column;
    }

    .basicDetails {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }
}

@media only screen and (max-width: 600px) {
    .basicDetailsBox {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }
    .basicDetailsRow {
        display: flex;
        flex-wrap: wrap;
    }
    .basicDetailsKey{
        width: 120px;
    }
}

@media only screen and (max-width: 600px) {
    .contents {
        flex-direction: column;
        gap: 20px;
    }
}