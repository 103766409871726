@import '../App.css';

.container{
    width: 660px;
    background-image: url("../images/sharedDocument/bg.jpg");
}

.mainSection{
    padding-left: 16px;
}

.contentSection{
    padding: 16px 0px;
    display: flex;
    justify-content: space-between;
}

/* second section */

.profile{
    position: relative;
    width: 660px;
    height: 600px;
    background-image: url("../images/sharedDocument/transpatrentBg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
}

.frame{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    width: 600px;
    height: auto;
    z-index: 2;
    padding: 20px;
    overflow: hidden;
}
