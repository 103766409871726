@import '../App.css';

.dashboardHeadingContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.profileDetails {
    font-size: 12px;
    font-weight: 600;
}

.approvedDate {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.phoneNumber {
    font-size: 12px;
    font-weight: 600;
}

.datesContainer{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.datesContainer span{
    font-size: 12px;
}

.checkcol{
    display: flex;
    align-items: center;
    gap: 10px;
}

.checkbox {
    -webkit-appearance: none;
    appearance: none;
    height: 14px;
    width: 14px;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    outline: none;
    background: url(../images/check.svg) no-repeat;
    background-size: 13px;
    background-position: center;
}

.checkbox:checked {
    background-color: var(--primary-color);
}

.notifyStateIconsBar{
    display: flex;
    gap: 10px;
}