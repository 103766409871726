
.reportsSection{
    margin-bottom: 100px;
}

.headerSection{
    margin-top: 50px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.rangePicker{
    height: 40px;
}

.TableContainer{
    width: 100%;
    overflow: hidden;
    margin-top: 50px;
}

.exportCsvBtn{
    background: rgb(234, 134, 182);
    border: none;
    outline: none;
    border-radius: 8px;
    width: fit-content;
    height: 40px;
    padding: 0px 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.exportCsvBtnText{
    text-decoration: none;
    color: white;
    font-weight: 800;
    font-size: 14px;
    line-height: 14px;
    display: inline;
    height: fit-content;
}

@media only screen and (max-width: 600px) {
    .headerSection{
        flex-direction: column;
        justify-content: left;
        align-items: self-start;
        gap: 20px;
    }
}