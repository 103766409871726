
.notificationContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.notificationDetails{
    margin-left: 10px;
}

.notificationTitle{
    font-weight: 700;
    padding: 0;
    margin: 0;
}

.notificationbody{
    margin: 0;
    padding: 0;
}