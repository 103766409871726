
/* feedback form list page */
.headerSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container{
    width: 100%;
    height: auto;
    border-radius: 16px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    padding: 0 25px;
    overflow-x: auto;
}

.feedbackform{
    width: 100%;
    border-bottom: 1px solid #E5E5E5;
    padding: 25px 0;
}

.feedbackContents{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.feedbackTexts{
    flex-basis: 90%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-decoration: none;
    cursor: pointer;
}

.deleteIconsFlex{
    flex-basis: 10%;
}

.feedbackTitle{
    font-size: 16px;
    line-height: 17px;
    font-weight: 600;
    margin-bottom: 10px;
}

.feedbackDesc{
    display: inline;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.feedbackContents:hover .deleteIconContainer{
    display: flex;
}

.deleteIconContainer{
    display: none;
    align-items: center;
    justify-content: space-around;
    width: 40px;
    height: 40px;
    background: rgba(0,0,0,0.05);
    border-radius: 50px;
    cursor: pointer;
}

.feedbackTime{
    display: inline;
    font-size: 10px;
    font-weight: 400;
    padding: 0;
    margin: 0;
}

.feedbackClockIcon{
    margin-bottom: 2px;
    margin-right: 5px;
}

/* Add feedbacks page */

.breadCrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 50PX;
}

.activeLink {
    font-size: 14px;
    color: #212B36;
    font-weight: 500;
    text-decoration: none;
}

.activeLink:hover {
    color: #212B36;
    text-decoration: underline;
}

.inActiveLink {
    color: #637381;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.dot {
    width: 10px;
    color: #637381;
}

.feedbackFormContainer{
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 50px;
    width: 85%;
}

.description{
    width: 100%;
    height: 50px;
    border: 1px solid var(--input-inactive);
    border-radius: 8px;
    font-weight: 400;
    padding-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
}

.btnsContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

@media only screen and (max-width: 1200px) {
    .feedbackFormContainer{
        width: 100%;
    }
}

@media only screen and (max-width: 400px) {
    .feedbackContents{
        flex-direction: column;
        align-items: flex-start;
    }
}