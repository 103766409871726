
.addAdminFormContainer{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(99, 115, 129, 0.2);
    z-index: 15;
}

.addAdminForm{
    max-width: 600px;
    min-width: 300px;
    width: 100%;
    height: auto;
    background-color: white;
    padding: 30px;
    margin: 20px;
}

.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.heading{
    font-size: 20px;
    font-weight: 700;
}


.buttonGrp{
    width: 100%;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}