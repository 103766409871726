
.paginationContainer{
    height: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #E5E5E5;
}

.gotoPageLabel{
    margin-right: 16px;
    font-size: 14px;
    color: #212b36;
}

.defaultPage{
    margin-right: 16px;
    display: flex;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .paginationContainer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: auto;
        gap: 10px;
        padding: 10px 0;
    }
    .defaultPage{
        margin: 0;
    }
    .gotoPageLabel{
        margin-right: 10px;
    }
}