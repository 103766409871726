.deactivationPage {
    padding: 80px;
}

.heading {
    font-size: 16px;
    font-weight: 600;
}

.container {
    width: 100%;
    height: auto;
    padding: 30px;
}

.reasons {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.otherReason {
    margin-top: 50px;
    border: 1px solid #637381;
    border-radius: 12px;
    width: 400px;
    height: 100px;
    padding: 10px;
    font-size: 12px;
    color: #212B36;
}


.btnGroup{
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

/* bread crumbs */

.breadCrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 50PX;
}

.activeLink {
    font-size: 14px;
    color: #212B36;
    font-weight: 400;
    text-decoration: none;
}

.activeLink:hover {
    color: #212B36;
    text-decoration: underline;
}

.inActiveLink {
    color: #637381;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.dot {
    width: 10px;
    color: #637381;
}

@media only screen and (max-width: 900px) {
    .deactivationPage {
        padding: 50px;
    }
}

@media only screen and (max-width: 600px) {
    .deactivationPage {
        padding: 30px;
    }

    .container {
        padding: 16px;
    }

    .reasonLabel {
        font-size: 12px;
    }

    .otherReason {
        width: 100%;
    }

}