
@import '../App.css';

.headerSection {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.charts{
    display: flex;
    align-items: center;
    gap: 16px;
    height: 500px;
    margin-bottom: 100px;
}

.lineChartContainer{
    flex-flow: 60%;
    width: 60%;
    height: 100%;
}

.pieChartContainer{
    flex-flow: 40%;
    width: 40%;
    height: 100%;
}

/* Transaction History Table Row */

.subscriptionPlan{
    font-size: 12px;
    font-weight: 600;
}

.subscriptionDate{
    font-size: 10px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.transactionId{
    font-size: 12px;
    font-weight: 600;
}

/* Transaction History Mobile View */

.mobileViewContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.subscriptionDetails{
    min-width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.MobilesubscriptionPlan{
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 5px;
}
.MobilesubscriptionDate{
    font-size: 10px;
    font-weight: 500px;
    margin: 0;
    padding: 0;
}

/* Transaction card sections */

.transactionCardContainer{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
}

.transactionCard{
    width: 33.33%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    border: none;
    outline: none;
    padding: 20px;
    height: auto;
}

/* Transaction history table */

.searchAndSortContainer{
    display: flex;
    margin-bottom: 40px;
    justify-content: space-between;
    gap: 20px;
}

.searchBar{
    width: 50%;
    height: 40px;
}

.rangePicker{
    height: 40px;
}

.percentageRevenue{
    font-size: 14px;
    margin-left: 5px;
    font-weight: 200;
}

.positiveRevenue{
    color: var(--success-color) !important;
}

.negativeRevenue{
    color: var(--danger-color) !important; 
}

.nodifferenceRevenue{
    color: var(--danger-color) !important; 
}

@media only screen and (max-width: 768px) {
    .charts{
        flex-direction: column;
        height: 750px;
    }
    .lineChartContainer{
        flex-basis: 100%;
        width: 100%;
        height: 450px;
    }

    .pieChartContainer{
        flex-basis: 100%;
        width: 100%;
        height: 300px;
    }
    .transactionCard{
        height: 140px;
    }
}

@media only screen and (max-width: 600px) {
    .searchBar{
        width: 100%;
    }
    .searchAndSortContainer{
        display: flex;
        flex-direction: column;
    }

    .transactionCardContainer{
        flex-direction: column;
    }

    .transactionCard{
        width: 100%;
        flex-basis: 100%;
        height: auto;
    }
}