@import '../../App.css';

.SearchAndFilterBar{
    display: flex;
    align-items: center;
    margin: 30px 16px;
    gap: 20px;
}

.searchbarContainer{
    flex-basis: 50%;
}

.filterbarContainer{
    flex-basis: 50%;
    position: relative;
}

.search, .filter{
    display: flex;
    align-items: center;
    border: 1px solid #DCE0E4;
    border-radius: 8px;
    padding-right: 10px;
}

.searchbar, .filterbar{
    width: 100%;
    height: 40px;
}

.filterIcon{
    width: 30px;
    display: none;
}

.sliderContainer{
    margin-top: 10px;
    position: absolute;
    right: 0;
    width: 100%;
    height: 250px;
    border-radius: 10px;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    background-color: white;
    z-index: 5;
}

.slider{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.sliderLabel{
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.btngrp{
    display: flex;
    gap: 20px;
}

.searchBtn{
    display: inline-block;
}

.smallSearchBtn{
    display: none;
    height: 40px;
    width: 50px;
    background: var(--primary-color);
    border: none;
    outline: none;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .sliderContainer{
        width: 250px;
    }
    .SearchAndFilterBar{
        gap: 10px;
    }
    .searchbarContainer{
        flex-basis: 90%;
    }
    .filterbarContainer{
        flex-basis: 10%;
    }
    .filter{
        display: none;
    }
    .filterIcon{
        display: inline-block;
    }
    .searchBtn{
        display: none;
    }
    .smallSearchBtn{
        display: inline-block;
    }
}