@import url('../../App.css');
.general {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

/* image */

.userImage {
    width: 100%;
    flex-basis: 33.3333%;
    flex-grow: 0;
    max-width: 33.3333%;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    border-radius: 16px;
    height: fit-content;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.userVersion {
    align-self: flex-end;
    font-size: 10px;
    font-weight: 700;
    color: var(--primary-color);
    background-color: var(--primary-background-color);
    width: fit-content;
    margin: 0;
    padding: 6px 20px;
    border-radius: 5px;
}

.userImageContents {
    align-self: center;
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.imageBorder {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px dashed #919eab52;
    margin-bottom: 30px;
}

.referenceNumber{
    margin-top: 10px;
    font-size: 13px;
    font-weight: 500;
}

.userInfo {
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #212B36;
    margin-top: 15px;
    margin-bottom: 30px;
    text-align: center;
}

.profileBtnGrp {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 20px;
}

.profileBtn {
    width: 100px;
    height: 40px;
    border-radius: 10px;
    background-color: var(--primary-color);
    border: none;
    outline: none;
    color: white;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.profileBtnContent{
    display: flex;
    align-items: center;
    gap: 2px;
}

.profileIcons{
    width: 20px;
    height: 15px;
    object-fit: fill;
}

.galleryImagesSection{
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;
}

@media only screen and (max-width: 900px) {
    .userImage {
        width: 100%;
        flex-basis: 100%;
        flex-grow: 0;
        max-width: 100%;
    }
    .general{
        flex-direction: column;
    }
}

@media only screen and (max-width: 600px) {
    .profileBtnGrp{
        flex-direction: column;
        gap: 10px;
    }
}