
.itemsContainer{
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;
}

.photoUploadBtn{
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px dashed var(--input-inactive);
    height: 100px;
    width: 100px;
    border-radius: 20px;
}

.btnContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.addPhotoText{
    padding: 0;
    margin: 0;
    color: var(--text-secondary);;
}

.previewImage{
    height: 100px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px solid var(--input-inactive);
    border-radius: 20px;
    padding: 0 10px;
}

