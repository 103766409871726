
.searchBar{
    margin: 30px 16px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    gap: 20px;
}

.searchbarContainer {
    padding-right: 10px;
    height: 40px;
    width: 500px;
    border: 1px solid #DCE0E4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchBtn{
    display: inline-block;
}

.smallSearchBtn{
    display: none;
    height: 40px;
    width: 50px;
    background: var(--primary-color);
    border: none;
    outline: none;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .searchBtn{
        display: none;
    }
    .smallSearchBtn{
        display: inline-block;
    }
    .searchBar{
        width: 100%;
        gap: 18px;
    }
    .searchbarContainer {
        width: calc(100% - 100px);
    }
}


/* mobile view */

.requestMobileView{
    margin: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.requesterSection{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.statusDot{
    content: " ";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 5px;
    margin-left: 5px;
}