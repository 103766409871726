
.firstHeader, .firstRow{
    width: 20%;
}

.secondHeader, .secondRow{
    width: 20%;
}

.thirdHeader, .thirdRow{
    width: 10%;
}

.noHeading, .actionRow{
    color: transparent;
    width: 50%;
}

.mainHeading{
    font-weight: 600;
    font-size: 14px;
    color: #212B36;
    margin-left: 30px;
    margin-bottom: 0;
    padding: 0;
}

.secondaryHeading{
    color: #637381;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0;
    padding: 0;
}

.tableRows{
    height: 40px;
} 

.marginTop{
    margin-top: 10px;
}

.email{
    font-size: 12px;
    margin:0;
    padding: 0;
}

