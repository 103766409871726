
/* HEADER SECTION STARTS HERE  */

.headerSection{
    display: flex;
    justify-content: space-between;
}

.headerLogoContainer{
    background-color: white;
    border-radius: 0 0 10px 10px;
}

.headerLogoContainer img{
    width: 190px;
    height: auto;
    object-fit: contain;
    margin: 20px 18px 18px 18px; 
}

.headerContentSection{
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-right: 16px;
}

.headerContentSectionTitle{
    font-size: 18px;
    font-weight: 800;
    padding: 0;
    margin: 0;
    color: black;
}

.headerContentSectionPara{
    font-size: 17px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    color: black;
}

.primaryColorTitle{
    color: #E93396 !important;
}