@import '../App.css';

.requests {
    margin: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.expandIcons {
    margin-left: 10px;
}

.searchBar{
    margin: 30px 16px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    gap: 20px;
}

.searchbarContainer {
    padding-right: 10px;
    height: 40px;
    width: 500px;
    border: 1px solid #DCE0E4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchBtn{
    display: inline-block;
}

.smallSearchBtn{
    display: none;
    height: 40px;
    width: 50px;
    background: var(--primary-color);
    border: none;
    outline: none;
    border-radius: 8px;
}

@media only screen and (max-width: 600px) {
    .searchBtn{
        display: none;
    }
    .smallSearchBtn{
        display: inline-block;
    }
    .searchBar{
        width: 100%;
        gap: 18px;
    }
    .searchbarContainer {
        width: calc(100% - 100px);
    }
}

/* accordion */

.accordion {
    width: 100%;
    display: block;
    background-color: rgba(99, 115, 129, 0.03);
    padding: 10px 0 0 16px;
    margin-bottom: 20px;
    transition: all 0.5s;
}

.accordianContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.accordionKey {
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
    width: fit-content;
    width: 90px;
}

.accordionValue {
    font-size: 12px;
    font-weight: 600;
}

.iconBoxContainer {
    display: flex;
    align-items: center;
    justify-content: right;
    margin-right: 10px;
    margin-bottom: 5px;
}