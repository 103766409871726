.editPhoto {
    width: 145px;
    height: 145px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border: 1px dashed #919eab52;
    overflow: hidden;
}

.uploadedImageSection {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 125px;
    height: 125px;
    border-radius: 50%;
}

.photoEditBtn {
    transition: .5s ease;
  opacity: 0;
  position: absolute;
    width: 125px;
    height: 125px;
    border-radius: 50%;
    background-color: #63738108;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.addPhotoText {
    font-weight: 400;
    font-size: 12px;
    padding-top: 10px;
    margin: 0;
}

.uploadedImageSection:hover  .uploadImage{
    opacity: 0.3;
  }
  
  .uploadedImageSection:hover .photoEditBtn {
    opacity: 1;
  }
  