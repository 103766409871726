.background{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: rgba(99, 115, 129,0.2);
}
.sideBar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0%;
    width: 280px;
    display: block;
    border-right: 1px dashed #919eab1f;
    overflow: auto;
    background-color: white;
    transition: transform 0.5s;
    z-index: 10;
}

.navigationBar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    padding-left: 20px;
    padding-top: 50px;
}

.logo {
    width: 82px;
    height: 50px;
    object-fit: fill;
}

.navabarLogoContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.closeNavebarIcon{
    margin-right: 18px;
}

/* profile image container */

.profileBadgeConatainer {
    margin-top: 34px;
    margin-bottom: 60px;
    width: 240px;
    height: 70px;
    background-color: #919eab1f;
    border: none;
    border-radius: 12px;
    display: flex;
}

.profileBadge {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.profileContents {
    margin-left: 20px;
}

.profileName {
    font-family: 600;
    font-size: 16px;
    color: #212B36;
    margin: 0;
}

.profileStatus {
    font-weight: 300px;
    font-size: 11px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
}

/* navlink */

.navlink {
    width: 240px;
    height: 50px;
    border-radius: 10px;
    border: none;
    outline: none;
    margin-top: 15px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    cursor: pointer;
}

.navlink:hover {
    background-color: #919eab14;
}

.navlinkName {
    text-decoration: none;
    padding: 0 0 0 20px;
    margin: 0;
    display: inline;
    vertical-align: middle;
}