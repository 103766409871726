@import '../../App.css';

.SuccessStoriesSection{
    margin-bottom: 100px;
}

.headerSection{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchBar{
    margin: 30px 16px;
    width: calc(100% - 32px);
    display: flex;
    align-items: center;
    gap: 20px;
}

.searchbarContainer {
    padding-right: 10px;
    height: 40px;
    width: 500px;
    border: 1px solid #DCE0E4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.searchBtn{
    display: inline-block;
}

.smallSearchBtn{
    display: none;
    height: 36px;
    width: 40px;
    background: var(--primary-color);
    border: none;
    outline: none;
    border-radius: 8px;
}

.filterbar{
    display: inline-block;
    width: 100px;
}

.smallFilterbar{
    display: none;
}

.titleContainer{
    display: flex;
    align-items: center;
    gap: 10px;
}


.title p{
    padding: 0;
    margin: 0;
    width: 30ch;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.acceptActionBtn, .rejectActionBtn{
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 50%;
}

.acceptActionBtn:hover{
    background: var(--success-background);
}

.rejectActionBtn:hover{
    background: var(--danger-background);
}

/* SuccessStoriesActions */

.actionBtns{
    display: flex;
    flex-direction: row;
    gap: 30px;
}

@media only screen and (max-width: 600px) {
    .searchBtn, .filterbar{
        display: none;
    }
    .smallSearchBtn, .smallFilterbar{
        display: inline-block;
    }
    .searchBar{
        width: 100%;
        gap: 10px;
    }
    .searchbarContainer {
        width: calc(100% - 140px);
    }
}