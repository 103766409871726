
.addSuccessStories{
    margin-bottom: 100px;
}

.addSuccessStoriesForm{
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 85%;
}

.story{
    width: 100%;
    height: 100px;
    border: 1px solid var(--input-inactive);
    border-radius: 8px;
    font-weight: 400;
    padding-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: var(--text-secondary);
}

.btnsContainer{
    display: flex;
    flex-direction: row;
    gap: 16px;
}

/* Bread crumbs */

.breadCrumbs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 50PX;
}

.activeLink ,.sectionHeading{
    font-size: 14px;
    color: #212B36;
    font-weight: 500;
    text-decoration: none;
}

.sectionHeading{
    margin-left: 5px;
}

.activeLink:hover {
    color: #212B36;
    text-decoration: underline;
}

.inActiveLink {
    color: #637381;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.dot {
    width: 10px;
    color: #637381;
}


@media only screen and (max-width: 1200px) {
    .addSuccessStoriesForm{
        width: 100%;
    }
}