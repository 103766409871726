.transactionHistory {
    margin: 50px 0;
}

.transactionHistoryHeading {
    font-size: 17px;
    font-weight: 600;
    margin-bottom: 20px;
}

.transactionHistoryMobileView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.transactionPlanSection h3 {
    font-size: 14px;
    color: var(--text-primary);
    margin: 0;
    padding: 0;
}

.transactionPlanSection span {
    font-size: 12px;
    color: var(--text-secondary);
}

.transactionAmount{
    font-size: 14px;
    color: var(--text-primary);
    margin-bottom: 3px;
    padding: 0;
}

.transactionId {
    font-size: 12px;
    color: var(--text-secondary);
    margin: 0;
    padding: 0;
}

@media only screen and (max-width: 450px) {
    .transactionId {
        white-space: nowrap;
        width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}