
/* PROFILE IMAGE SECTION */

.profileImageSection{
    width: calc(45% - 16px);
    display: flex;
    flex-direction: column;
    gap: 16px;
    /* justify-content: space-between; */
}

.referenceNoContainer{
    padding: 16px;
    /* background-color: #E93396; */
    border-radius: 10px 0px 0px 10px;
    /* opacity: 0.7; */
    background-color: #ef6db1;
}

.referenceNoLabel{
    font-size: 16px;
    color: white;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.referenceNoValue{
    font-size: 30px;
    color: white;
    font-weight: 800;
    padding: 0;
    margin: 0;
}

.contactSection{
    border-radius: 10px;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.contactSectionContainer{
    padding: 16px;
    background-color: #fff;
    border-radius: 10px 0px 0px 10px;
    opacity: 0.7;
}

.contactFrame{
    position: absolute;
    top: 0px;
    left: 10px;
    width: 100%; 
    height: 100%; 
    background-image: url("../images//sharedDocument/contactFrame.png");
    background-repeat: no-repeat;
    background-size: 75% 100%;
    background-position: center;
    overflow: hidden;
}

.contactPara{
    font-size: 16px;
    font-weight: 500;
    padding: 0;
    /* color: black; */
    color: rgb(96, 96, 96);
    margin-bottom: 10px;
}

.contactNumber{
    font-size: 19px;
    font-weight: 800;
    padding: 0;
    margin: 0;
    color: black;
}

.contactName{
    font-size: 16px;
    font-weight: 600;
    padding: 0;
    margin-bottom: 0;
    margin-top: 5px;
    color: black;
}

.profileImgContainer{
    border-radius: 10px 0px 0px 10px;
    background-color: #ef6db1;
    /* opacity: 0.7; */
    position: relative;
}

.profileImageCover{
    margin: 16px;
    width: calc(100% - 32px);
    height: 250px;
    border-radius: 10px !important;
}

.profileImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px !important;
}

.whiteLogo{
    position: absolute;
    top: 32px;
    right: 32px;
}