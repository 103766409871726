.person {
    width: 100%;
    height: 100%;
}

.admin {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.personLink {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.personDetails {
    margin-left: 10px;
}

.personName {
    font-size: 14px;
    font-weight: 600;
    color: #212B36;
}

.personId {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0;
    margin: 0;
}

.personStatus{
    margin-top: 5px;
}

@media only screen and (max-width: 600px) {
    .personName {
        font-size: 12px;
    }

    .personId {
        font-size: 10px;
    }
}

@media only screen and (max-width: 360px) {
    .personName {
        white-space: nowrap;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}