.mainContainer{
    margin: 0px 16px;
    padding-bottom: 20px;
}
.profileContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
.notifyStateIconsBar {
    display: flex;
    gap: 8px;
}

.iconbarContainer{
    margin-top: 10px;
    margin-left: 50px;
}