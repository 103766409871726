
.emptyDataSection{
    margin-top: 100px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 50px;
    text-align: center;
}

.content {
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    padding: 0;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .emptyDataSection{
        width: 100%;
    }
    .heading {
        font-size: 16px;
    }
    .content {
        font-size: 14px;
    }
}