.profileDetailsSection{
    width: 55%;
    height: auto !important;
    border-radius: 10px;
    position: relative;
    display: inline-block;
}
.profileDetailsContainer{
    border-radius: 10px;
    padding: 16px;
    padding-bottom: 0px !important;
    background-color: white;
    height: 100%;
    opacity: 0.7;
}

.profileFrame{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%; 
    background-image: url("../images//sharedDocument/profileFrame.png");
    background-size: contain;
    background-position: center;
}