@import '../App.css';

.selectedUsersContainer{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 60px;
    background-color: white;
    z-index: 12;
    box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px, rgb(145 158 171 / 12%) 0px 12px 24px -4px;
    overflow-x: auto;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.selectedUsers{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.copyIcon{
    position: fixed;
    right: 50px;
    bottom: 50px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid  var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
}