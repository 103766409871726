@import '../../App.css';

.container {
    width: 95%;
    margin: 0px 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.expandIcons {
    margin-left: 10px;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: rgba(99, 115, 129, 0.03);
    padding: 20px 16px;
    margin-bottom: 20px;
    transition: all 0.5s;
}

.accordianContent {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.accordionKey {
    font-size: 12px;
    font-weight: 300;
    margin-right: 20px;
    margin-bottom: 0;
    padding: 0;
}

.accordionValue {
    font-size: 12px;
    font-weight: 600;
    padding: 0;
    margin: 0;
}

.checkbox {
    -webkit-appearance: none;
    height: 14px;
    width: 14px;
    border: 1px solid var(--primary-color);
    border-radius: 2px;
    outline: none;
    background: url(../../images/check.svg) no-repeat;
    background-size: 13px;
    background-position: center;
    margin-right: 5px;
}

.checkbox:checked {
    background-color: var(--primary-color);
}

.personContainer{
    width: 100%;
}