@import '../App.css';

.PersonalDetailsStrip{
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    padding-bottom: 14px !important;
    gap: 20px;
}

.keys, .value{
    line-height: 14px;
    font-size: 13px;
    color: black;
    padding: 0;
    margin: 0;
}
  .value {
    font-weight: 600;
    width: calc(100% - 140px);
    overflow: hidden;
  }
  
.keys{
    width: 120px;
    font-weight: 400;
}