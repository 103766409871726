
.imageModelBox{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 900px;
    padding: 30px;
    border: none;
    background-color: white;
    outline: 0;
    border-radius: 10px;
}

.carouselImages{
    width: 100%;
    height: 700px;
    object-fit: cover;
}

.loadingScreen{
    width: 100%;
    height: 700px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.loadingGIF{
    width: 30%;
    height: auto;
    object-fit: fill;
}

@media only screen and (max-width: 1000px) {
    .imageModelBox{
        width: 600px;
        padding: 10px;
        border-radius: 5px;
    }
    .carouselImages, .loadingScreen{
        height: 500px;
    }
}

@media only screen and (max-width: 700px) {
    .imageModelBox{
        width: 300px;
    }
    .carouselImages, .loadingScreen{
        height: 300px;
    }
}