.mainExportPdfContainer{
    margin: 50px 10px;
}

.exportPdfTitileComponent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 50px;
}

.exportPdfTitile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.label {
    padding: 0;
    margin: 0;
}

.exportPdfComponent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}



.sharedComponent {
    width: fit-content;
    height: fit-content;
}

@media only screen and (max-width: 420px) {
    .exportPdfTitile {
        flex-direction: column;
    }

    .exportPdfComponentContainer{
        width: 300px;
        overflow-x: auto;
    }
}