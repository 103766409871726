  .circularProgressBarContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
  }

  .circularProgressBar {
    width: 200px;
    font-weight: 700;
    font-style: bold;
  }

  .subscriptionContent{
    display: inline-block;
  }

  .subscriptionPlan{
    font-size: 20px;
    font-weight: 800;
    margin-bottom: 30px;
  }

  .subscriptionDays{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .subscriptionPrice{
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

@media only screen and (max-width: 600px) {
  .circularProgressBarContainer{
    flex-direction: column;
  }
  .subscriptionContent{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}